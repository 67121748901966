import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"./logo.png"} className="App-logo" alt="logo" />
        <p>
        Coming soon!
        </p>
        <a className="App-link"href="https://t.me/polyvertex" target="_blank">Telegram (English)</a>
        <a className="App-link"href="https://t.me/polyvertex_announcements" target="_blank">Telegram (Announcements)</a>
        <a className="App-link"href="https://t.me/polyvertex_spanish" target="_blank">Telegram (Español)</a>
       
      </header>
    </div>
  );
}

export default App;
